import React, { useState } from "react";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import {
  navyBlue,
  mainRed,
  mainWhite,
  aku,
  innerWidth,
  transHover,
  gotham,
  gothamBold,
  screen,
} from "../components/common/variables";
import { GatsbyImage } from "gatsby-plugin-image";
import VideoModal from "../components/video-modal";
import ReactPlayer from "react-player";
import Arrow from "../images/svg/right-arrow.svg";
import { Link as Spy } from "react-scroll";
import Obfuscate from "react-obfuscate";
import { getVimeoId } from "../components/common/functions";
import { IoIosPlay } from "react-icons/io";
import logo from "../images/tenalphas-moving-content.jpg";

const Wrapper = styled.div`
  padding-top: 124px;
  @media ${screen.small} {
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      font-size: 2.7rem;
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .description {
    max-width: ${innerWidth};
    color: ${navyBlue};
    margin: 30px auto 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    p {
      font-family: ${gotham};
      font-size: 1rem;
      margin-top: 16px;
      max-width: 1000px;
      @media ${screen.small} {
        font-size: 1.1rem;
      }
      &:first-child {
        margin-top: 0;
      }
    }
    strong {
      font-family: ${gothamBold};
    }
    a {
      color: ${navyBlue};
      font-family: ${gothamBold};
      &:hover {
        text-decoration: underline;
      }
    }

    h1 {
      font-size: 2.2rem;
      font-family: ${gothamBold};
      margin: 8px 0;
      @media ${screen.small} {
        font-size: 2.2rem;
        margin: 10px 0;
      }
    }
    h2 {
      font-size: 1.8rem;
      font-family: ${gothamBold};
      margin: 8px 0;
      @media ${screen.small} {
        font-size: 2rem;
        margin: 10px 0;
      }
    }
    h3 {
      font-size: 1.6rem;
      font-family: ${gothamBold};
      margin: 8px 0;
      @media ${screen.small} {
        font-size: 1.8rem;
        margin: 10px 0;
      }
    }
    h4 {
      font-size: 1.4rem;
      font-family: ${gothamBold};
      margin: 8px 0;
      @media ${screen.small} {
        font-size: 1.6rem;
        margin: 10px 0;
      }
    }
    h5 {
      font-size: 1.3rem;
      font-family: ${gothamBold};
      margin: 8px 0;
      @media ${screen.small} {
        font-size: 1.4rem;
        margin: 10px 0;
      }
    }
    h6 {
      font-size: 1.15rem;
      font-family: ${gothamBold};
      margin: 8px 0;
      @media ${screen.small} {
        font-size: 1.2rem;
        margin: 10px 0;
      }
    }
  }

  .team {
    max-width: ${innerWidth};
    margin: 90px auto 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__heading {
      color: ${navyBlue};
      font-family: ${aku};
      font-size: 2.1rem;
      vertical-align: middle;
      margin-bottom: 45px;
      @media ${screen.small} {
        font-size: 2.7rem;
      }

      a {
        pointer-events: none;
      }

      svg {
        width: 14px;
        margin-right: 8px;
        vertical-align: middle;
        @media ${screen.small} {
          width: 19px;
          margin-right: 14px;
        }
        @media ${screen.medium} {
          width: 24px;
        }

        .body {
          fill: ${navyBlue};
          transition: ${transHover};
        }
      }

      .active {
        .body {
          fill: ${mainRed};
        }
      }
    }

    &__member-list {
      margin-top: 20px;

      .member {
        margin-bottom: 80px;
        @media ${screen.small} {
          margin-bottom: 90px;
        }
        @media ${screen.medium} {
          margin-bottom: 120px;
        }
        @media ${screen.large} {
          margin-bottom: 180px;
        }
        &:last-child {
          margin-bottom: 0;
        }

        .member-row {
          display: flex;
          flex-direction: column;
          @media ${screen.small} {
            flex-direction: row;
          }

          .name {
            color: ${navyBlue};
            font-family: ${gothamBold};
            font-size: 1.25rem;
            display: none;
            @media ${screen.small} {
              display: block;
            }

            &--mobile {
              font-size: 1.15rem;
              display: block;
              margin-bottom: 2px;
              @media ${screen.small} {
                display: none;
              }
            }
          }

          .position {
            color: ${navyBlue};
            font-family: ${gothamBold};
            font-size: 1.1rem;
            display: none;
            @media ${screen.small} {
              display: block;
            }

            &--mobile {
              font-size: 1.05rem;
              display: block;
              margin-bottom: 10px;
              @media ${screen.small} {
                display: none;
              }
            }
          }

          &__img {
            width: 100%;
            max-width: 480px;
            @media ${screen.small} {
              max-width: 200px;
            }
            @media ${screen.medium} {
              max-width: 250px;
            }
            @media ${screen.large} {
              max-width: 290px;
            }
          }

          &__texts {
            max-width: 950px;
            @media ${screen.small} {
              margin-left: 35px;
            }
            @media ${screen.medium} {
              margin-left: 35px;
            }
            @media ${screen.large} {
              margin-left: 50px;
            }

            .description {
              margin-top: 24px;
              padding: 0;
              @media ${screen.small} {
                margin-top: 10px;
              }

              p {
                font-family: ${gotham};
                font-size: 1rem;
                margin-top: 16px;
                @media ${screen.small} {
                  font-size: 1.1rem;
                }
                &:first-child {
                  margin-top: 0;
                }
              }
              strong {
                font-family: ${gothamBold};
              }
              a {
                color: ${navyBlue};
                font-family: ${gothamBold};
                &:hover {
                  text-decoration: underline;
                }
              }

              h1 {
                font-size: 2.2rem;
                font-family: ${gothamBold};
                margin: 8px 0;
                @media ${screen.small} {
                  font-size: 2.2rem;
                  margin: 10px 0;
                }
              }
              h2 {
                font-size: 1.8rem;
                font-family: ${gothamBold};
                margin: 8px 0;
                @media ${screen.small} {
                  font-size: 2rem;
                  margin: 10px 0;
                }
              }
              h3 {
                font-size: 1.6rem;
                font-family: ${gothamBold};
                margin: 8px 0;
                @media ${screen.small} {
                  font-size: 1.8rem;
                  margin: 10px 0;
                }
              }
              h4 {
                font-size: 1.4rem;
                font-family: ${gothamBold};
                margin: 8px 0;
                @media ${screen.small} {
                  font-size: 1.6rem;
                  margin: 10px 0;
                }
              }
              h5 {
                font-size: 1.3rem;
                font-family: ${gothamBold};
                margin: 8px 0;
                @media ${screen.small} {
                  font-size: 1.4rem;
                  margin: 10px 0;
                }
              }
              h6 {
                font-size: 1.15rem;
                font-family: ${gothamBold};
                margin: 8px 0;
                @media ${screen.small} {
                  font-size: 1.2rem;
                  margin: 10px 0;
                }
              }
            }
          }
        }

        &__video {
          height: 212px;
          position: relative;
          max-width: 1180px;
          margin-top: 45px;
          @media ${screen.small} {
            height: 370px;
          }
          @media ${screen.medium} {
            height: 440px;
            max-width: 800px;
          }
          @media ${screen.large} {
            height: 570px;
            max-width: 980px;
          }

          .video-label {
            cursor: pointer;
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 1.1rem;
            position: absolute;
            height: 100%;
            width: 100%;
            padding-top: 12px;
            padding-left: 15px;
            font-size: 0;
            transition: ${transHover};
            @media ${screen.small} {
              font-size: 1.55rem;
              padding-top: 20px;
              padding-left: 25px;
            }
            @media ${screen.withCursor} {
              &:hover {
                background: rgba(200, 29, 41, 0.9);
              }
            }
          }

          .video-label-mobile {
            color: ${navyBlue};
            font-family: ${gotham};
            font-size: 0.9rem;
            margin-top: 7px;
            @media ${screen.small} {
              display: none;
            }
          }

          .play-icon {
            cursor: pointer;
            pointer-events: none;
            color: ${mainWhite};
            display: block;
            font-size: 3.5rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media ${screen.small} {
              font-size: 4.5rem;
            }
          }

          .thumbnail {
            width: 100% !important;
            height: 100% !important;
            pointer-events: none;
          }

          .react-player__play-icon,
          .react-player__shadow {
            display: none !important;
          }
        }
      }
    }
  }

  .join {
    padding-top: 90px;
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }
    &__heading {
      color: ${navyBlue};
      font-family: ${aku};
      font-size: 2.1rem;
      vertical-align: middle;
      margin-bottom: 7px;
      @media ${screen.small} {
        font-size: 2.7rem;
        margin-bottom: 45px;
      }

      a {
        pointer-events: none;
      }

      svg {
        width: 14px;
        margin-right: 8px;
        vertical-align: middle;
        @media ${screen.small} {
          width: 19px;
          margin-right: 14px;
        }
        @media ${screen.medium} {
          width: 24px;
        }

        .body {
          fill: ${navyBlue};
          transition: ${transHover};
        }
      }

      .active {
        .body {
          fill: ${mainRed};
        }
      }
    }

    &__description {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1rem;
      max-width: 880px;
      margin-bottom: 25px;
      @media ${screen.small} {
        font-size: 1.1rem;
        margin-bottom: 45px;
      }

      a {
        color: ${navyBlue};
        font-family: ${gothamBold};
        transition: ${transHover};
        &:hover {
          color: ${mainRed};
        }
      }
    }
  }

  .bottom-banner {
    margin-top: 45px;
    @media ${screen.small} {
      margin-top: 90px;
    }
    @media ${screen.medium} {
      margin-top: 150px;
    }

    img {
      display: block;
    }
  }
`;

const AboutPage = ({ data }) => {
  // basic hack to trigger the video modal component to watch location hash changes
  const [triggerLightboxComponent, setTriggerLightboxComponent] = useState(
    false
  );

  // Prismic content
  const pageTitle = data.content.data.page
    ? data.content.data.page.text
    : `About Us`;
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : `About Us`;
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : `About Us`;
  const intro = data.content.data.introduction.html;
  const members = data.content.data.members;
  const joinDescription = data.content.data.description;
  const bottomAltImg = data.content.data.bottom_image.alt || "";
  const bottomImg = data.content.data.bottom_image.gatsbyImageData;
  const email = data.details.data.email_address
    ? data.details.data.email_address
    : `team@tenalphas.com.au`;

  // open video modal
  const viewVideo = (videoId) => {
    window.history.replaceState(null, null, `#${videoId}`);
    setTriggerLightboxComponent(!triggerLightboxComponent);
  };

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} image={logo} />
      <Wrapper>
        <h2 className="heading">
          <Arrow />
          {pageTitle}
        </h2>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: intro }}
        />
        <main id="team-list" className="team">
          <h2 className="team__heading">
            <Spy
              to="team-list"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-240}
            >
              <Arrow />
            </Spy>
            MEET THE DIRECTORS
          </h2>
          <ul className="team__member-list">
            {members.map((member, i) => (
              <li key={i} className="member">
                <div className="member-row">
                  <h3 className="name name--mobile">{member.name}</h3>
                  <p className="position position--mobile">{member.position}</p>
                  <div className="member-row__img">
                    {member.image.gatsbyImageData && (
                      <GatsbyImage
                        image={member.image.gatsbyImageData}
                        alt={member.image.alt || "Staff photo"}
                      />
                    )}
                  </div>
                  <div className="member-row__texts">
                    <h3 className="name">{member.name}</h3>
                    <p className="position">{member.position}</p>
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: member.description1.html,
                      }}
                    />
                  </div>
                </div>
                {member.video.document && (
                  <div
                    className="member__video"
                    onClick={() =>
                      viewVideo(
                        getVimeoId(member.video.document.data.video_url)
                      )
                    }
                  >
                    <h5 className="video-label">{member.video_label}</h5>
                    <IoIosPlay className="play-icon" />
                    <ReactPlayer
                      className="thumbnail"
                      url={member.video.document.data.video_url}
                      light={`https://vumbnail.com/${getVimeoId(
                        member.video.document.data.video_url
                      )}.jpg`}
                    />
                    <h5 className="video-label-mobile">{member.video_label}</h5>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </main>
        <section id="join-us" className="join">
          <h2 className="join__heading">
            <Spy
              to="join-us"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-150}
            >
              <Arrow />
            </Spy>
            WANT TO JOIN THE TEAM?
          </h2>
          <p className="join__description">{joinDescription}</p>
          <p className="join__description">
            Email us at <Obfuscate email={email} />
          </p>
        </section>
        {bottomImg && (
          <div className="bottom-banner">
            <GatsbyImage image={bottomImg} alt={bottomAltImg} />
          </div>
        )}

        <VideoModal triggerLightboxComponent={triggerLightboxComponent} />
      </Wrapper>
    </Layout>
  );
};

export default AboutPage;

export const dataQuery = graphql`
  {
    content: prismicAboutUsPage {
      data {
        introduction {
          html
        }
        description
        title_tag
        meta_description
        page {
          text
        }
        bottom_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        members {
          name
          position
          image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
          description1 {
            html
          }
          video_label
          video {
            document {
              ... on PrismicProject {
                data {
                  video_url
                }
              }
            }
          }
        }
      }
    }
    details: prismicGeneralDetails {
      data {
        email_address
      }
    }
  }
`;
